body, #root{
    height: 100%;
}
.lds-ring {
    display: inline-block;
    position: relative;
    width: 40px;
    height: 40px;
}
.lds-ring div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    top:10px;
    width: 20px;
    height: 20px;
    border: 3px solid #fff;
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: #fff transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
    animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
    animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
    animation-delay: -0.15s;
}
@keyframes lds-ring {
    0 % {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
form.auth-form .auth-submit-btn.disabled{
    cursor: default!important;
    background: gray!important;
    &:hover{
        background-color: gray!important;
    }
}
@supports (-webkit-overflow-scrolling: touch) {
    input:focus, textarea:focus {
        font-size: 16px;
    }
}
.lds-ring.dark div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    top:10px;
    width: 18px!important;
    height: 18px!important;
    border: 3px solid #fff;
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: #2467eb transparent transparent transparent!important;
}
.notification-close {
    cursor: pointer;
    border-radius: 3px;
    padding: 1px;
    position: absolute;
    top: 6px;
    bottom: auto;
    left: auto;
    right: 8px;
}